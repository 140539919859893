@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}